html, body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
    background-image: url(./assets/bombrange_background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    min-height: 100vh;
    min-width: 360px;
}

/* .icon-hover:hover {
    color: white;
}

.files-ui-file-mosaic-main-container .files-ui-file-mosaic-file-name {
    color: white !important;
} */